// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-grafika-js": () => import("./../../../src/pages/grafika.js" /* webpackChunkName: "component---src-pages-grafika-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-weby-js": () => import("./../../../src/pages/weby.js" /* webpackChunkName: "component---src-pages-weby-js" */),
  "component---src-pages-wp-admin-js": () => import("./../../../src/pages/wp-admin.js" /* webpackChunkName: "component---src-pages-wp-admin-js" */)
}

